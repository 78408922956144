<template>
  <vs-card>
    <div slot="header">
      <h4>{{ item.name }}</h4>
      <p
        class=""
        style="color: rgb(184, 194, 204);"
      >
        {{ item.desc }}
      </p>
    </div>
    <vs-row
      vs-type="flex"
      vs-justify="space-between"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="10"
      >
        <vs-input
          placeholder="upload file"
          icon-pack="feather"
          icon="icon-plus"
          icon-after
          vs-icon-after="true"
          @click="$refs.file.click()"
          class="w-full"
          readonly
        />
        <input
          type="file"
          hidden
          ref="file"
          @change="uploadImage($event)"
        >
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="2"
      >
        <vs-button
          type="filled"
          @click.prevent="configUpdated"
        >
          حفظ
        </vs-button>
      </vs-col>
    </vs-row>
    <a
      v-if="item.value"
      :href="item.value"
      target="_blank"
    >رابط الملف</a>
  </vs-card>
</template>

<script>

export default {
  name: "FileInputConfig",
  data() {
    return {
      file: null
    };
  },
  methods: {
    configUpdated() {
      if (!this.file) {
        this.$vs.notify({
          title: 'خطأ',
          text: 'يرجى اختيار ملف',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
      } else {
        this.item.value = this.file;
        this.$emit('configUpdated', this.item);
      }
    },
    uploadImage(e) {
      this.file = e.target.files[0];
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
};
</script>
